<template>
  <div class="leave-app-form mt-14 mt-sm-16 white" style="height: calc(100vh - 56px)!important;overflow-y: auto;">
    <div class="d-flex fill-width justify-center">
      <div class="leave-app-form-area px-4 px-sm-0">
        <div class="d-flex flex-column-reverse flex-sm-row justify-sm-space-between fill-width align-center py-8">
          <div class="subtitle-1 text-sm-h5 font-weight-bold text-left">
            LEAVE APPLICATION FORM
          </div>
          <div class="logo py-2">
            <img src="/images/logo.png">
          </div>
        </div>
        <v-divider />
        <div class="d-flex flex-column flex-sm-row fill-width align-start align-sm-center justify-sm-space-between">
          <div>
            <!-- <v-checkbox
              v-model="formRevised"
              label="Revised (Please tick only if it is a revised request)"
              hide-details
              class="mr-4"
            /> -->
          </div>
          <div class="pt-6">
            <v-menu
              v-model="formDateMenu"
              :disabled="((parseInt(data.id) && parseInt(data.status)) ? true : false) || false"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formDate"
                  readonly
                  label="Date"
                  block
                  hide-details
                  color="black"
                  class="pt-0 mt-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formDate"
                @input="formDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <v-row no-gutters class="form-app-area py-4 py-sm-10">
          <v-col v-for="(f, iF) in form" :key="'form-' + iF" cols="12" class="d-flex flex-column flex-sm-row align-start align-sm-center py-4 py-sm-1">
            <div class="labeled-form d-flex justify-start justify-sm-space-between align-center">
              <div class="text-left">
                {{ f.label }}
                <div v-if="f.labelsub" class="gret--text" style="font-size: 10px;">
                  {{ f.labelsub }}
                </div>
              </div>
              <span class="ml-1">
                :
              </span>
            </div>
            <div class="pl-0 pl-sm-8 fill-width d-flex align-center">
              <div v-if="f.type === 'text' || !f.type" class="fill-width d-flex align-center">
                <v-text-field
                  v-if="f.id === 5"
                  v-model.number="form[iF].value"
                  :disabled="parseInt(data.status) ? true : false"
                  placeholder
                  hide-details
                  block
                  color="black"
                  class="pt-0 mt-0"
                  @keypress="$NumberOnly"
                  @keyup="parseInt(form[iF].value) ? false : form[iF].value = 1"
                  @blur="parseInt(form[iF].value) ? false : form[iF].value = 1"
                />
                <v-text-field
                  v-else
                  v-model="form[iF].value"
                  :disabled="parseInt(data.status) ? true : false"
                  placeholder
                  hide-details
                  block
                  color="black"
                  class="pt-0 mt-0"
                />
              </div>
              <div v-if="f.type === 'textarea'" class="fill-width d-flex align-center">
                <v-textarea
                  v-model="form[iF].value"
                  :disabled="parseInt(data.status) ? true : false"
                  hide-details
                  block
                  rows="3"
                  color="black"
                  class="pt-0 mt-0"
                />
              </div>
              <div v-if="f.type === 'date'">
                <v-menu
                  v-model="form[iF].menu"
                  :close-on-content-click="false"
                  :disabled="parseInt(data.status) ? true : false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form[iF].value"
                      readonly
                      block
                      hide-details
                      color="black"
                      class="pt-0 mt-0"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form[iF].value"
                    @input="form[iF].menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-if="f.type === 'daterange'" class="fill-width d-flex align-center">
                <v-menu
                  :ref="`menu${iF}`"
                  v-model="form[iF].menu"
                  :disabled="parseInt(data.status) ? true : false"
                  :return-value.sync="form[iF].value"
                  left
                  offset-y
                  :close-on-content-click="false"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="dateRangeText(f.value)"
                      readonly
                      dense
                      hide-details
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="form[iF].value = []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form[iF].value"
                    no-title
                    light
                    class="force-light-font"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      class="text-capitalize"
                      small
                      @click="form[iF].menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      class="text-capitalize"
                      small
                      @click="$refs[`menu${iF}`][0].save(form[iF].value)"
                    >
                      Ok
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-if="f.type === 'select' && f.id === 3" class="fill-width d-flex align-center">
                <v-radio-group v-model="form[iF].value" :disabled="parseInt(data.status) ? true : false" row hide-details>
                  <template v-for="(fo, iFo) in $store.state.pilot_status_form_leave">
                    <v-radio
                      :value="parseInt(fo.status)"
                      :key="iFo"
                      :label="fo.name"
                      hide-details
                      class="mr-4 mb-2"
                    />
                  </template>
                </v-radio-group>
              </div>
            </div>
          </v-col>
          <v-col v-if="!parseInt(data.status)" cols="12" class="d-flex align-center justify-space-between pt-6">
            <v-btn :block="!(this.data.id)" color="primary" depressed class="rounded-0 text-capitalize" @click.prevent="FORM_LEAVE_APP_PROCESS()">
              {{ this.data.id ? 'Update' : 'Create' }}
            </v-btn>
            <v-btn v-if="this.data.id" dark color="purple darken-1" depressed class="rounded-0 text-capitalize" @click.prevent="FORM_LEAVE_APP_PROCESS(1)">
              Send Form
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
const formDefault = [
  {
    id: 1,
    label: 'Name',
    type: 'text',
    value: ''
  },
  {
    id: 2,
    label: 'Current Base',
    type: 'text',
    value: ''
  },
  {
    id: 3,
    label: 'Leave Type',
    type: 'select',
    value: -1
  },
  {
    id: 4,
    label: 'Leave (From ~ To) Date',
    type: 'daterange',
    menu: false,
    value: []
  },
  {
    id: 5,
    label: 'Total Day(s) Requested',
    type: 'text',
    value: 1
  },
  {
    id: 6,
    label: 'KITAS/IMTA Exp. Date',
    type: 'date',
    value: null
  },
  {
    id: 7,
    label: 'Medical Exp. Date',
    type: 'date',
    value: null
  },
  {
    id: 8,
    label: 'License/PPC Exp. Date',
    type: 'date',
    value: null
  },
  {
    id: 9,
    label: 'Comment',
    labelsub: 'Reason for the leave and any other significant informations related to your documents (e.g: Home license and medical expired, Quarantine days expected etc.',
    type: 'textarea',
    value: ''
  }
]
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: (vm) => ({
    formRevised: false,
    formDateMenu: false,
    formDate: null,
    form: [].concat(formDefault),
    RevisionHistory: []
  }),
  watch: {
    data (v) {
      this.INIT_DATA()
    }
  },
  mounted () {
    this.INIT_DATA()
  },
  methods: {
    INIT_DATA () {
      this.RevisionHistory = []
      if (this.data ? (parseInt(this.data.id) || false) : false) {
        this.formDate = this.$localDT(this.data.created, 'datedefault')
        this.form = [].concat(this.form).map((r) => {
          const f = this.data.form.find(s => s.label === r.label && s.type === r.type)
          r.value = f ? f.value : ''
          return r
        })
        this.LoadRevision(parseInt(this.data.id))
      } else {
        this.form = [].concat(formDefault).map((r) => {
          if (r.id === 1) {
            r.value = (this.$store.state.me ? (this.$store.state.me.name + (this.$store.state.me.name_last ? (' ' + this.$store.state.me.name_last) : '')) : '')
          } else if (r.id === 3) {
            r.value = -1
          } else {
            r.value = parseInt(r.id) === 4 ? [] : ''
          }
          return r
        })
      }
    },
    LoadRevision (id) {
      if (id) {
        this.$store.dispatch('forms/FORM_LEAVE_GET_ONE', id)
          .then((res) => {
            if (res.status) {
              this.RevisionHistory = res.data.data.revise_history || []
            }
          })
      } else {
        this.RevisionHistory = []
      }
    },
    FORM_LEAVE_APP_PROCESS (status) {
      if (!confirm('Are you sure you want to send this form ?')) {
        return false
      }
      const getDates = this.form.find(r => r.type === 'daterange')
      let dateForm = null
      let dateTo = null
      if (this.ErrorForm()) {
        this.$toastNotif({
          message: 'Please check your input!',
          type: 'info'
        })
        return false
      } else if (getDates) {
        if (getDates.value ? getDates.value.length === 2 : false) {
          const g = this.GetFromTo(getDates.value)
          if (g) {
            dateForm = g.from
            dateTo = g.to
          }
        } else {
          this.$toastNotif({
            message: 'Please input valid Date Ranges!',
            type: 'info'
          })
        }
      } else {
        this.$toastNotif({
          message: 'Please input Date Ranges!',
          type: 'info'
        })
        return false
      }
      const params = {
        id: parseInt(this.data.id) || null,
        crew: this.$store.state.me.id,
        dateFrom: dateForm,
        dateTo: dateTo,
        form: this.$ArrayToStr(this.form),
        created: parseInt(this.data.id) ? this.data.created : null,
        status: status || parseInt(this.data.status) || 0
      }
      this.$store.dispatch('forms/FORM_LEAVE_PROCESS', params)
        .then((res) => {
          let stop = false
          if (res.status) {
            if (res.data.data.id) {
              this.$toastNotif({
                message: (params.id ? 'Updated!' : 'Created!'),
                type: 'success'
              })
              stop = true
              this.$router.go(-1)
            }
          }
          if (!stop) {
            this.$toastNotif({
              message: 'Ops, something went wrong!',
              type: 'error'
            })
          }
        })
    },
    ErrorForm () {
      return this.form.filter(r => {
        if (r.value || (r.id === 3)) {
          if (typeof r.value === 'string' && r.value) {
            return false
          } else if (r.id === 3) {
            return !(parseInt(r.value) > -1)
          } else if (r.id === 5) {
            return !(parseInt(r.value) > 0)
          } else if (r.value.length === 2) {
            return false
          }
          return true
        } else {
          return true
        }
      }).length
    },
    SaveDates (menu, dates) {
      this.$refs[menu].save(dates)
    },
    dateRangeText (dates) {
      const dt = this.GetFromTo(dates)
      if (dt) {
        return dt.from + ' ~ ' + dt.to
      } else {
        return dates.join(' ~ ')
      }
    },
    GetFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return {
              from: fromTo[0],
              to: fromTo[1]
            }
          } else {
            return {
              from: fromTo[1],
              to: fromTo[0]
            }
          }
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss">
.leave-app-form {
  background-color: #fafafa;
  .leave-app-form-area {
    background-color: #fff;
    max-width: 900px;
    overflow-y: auto;
    .logo {
      img {
        height: 40px;
      }
    }
    // .leave-app-form-forms {
    // }
    .form-app-area {
      .labeled-form {
        width: 250px;
      }
      .v-text-field {
        >.v-input__control {
          >.v-input__slot {
            &::before {
              border-color: rgba(0, 0, 0, .5)!important;
              border-width: 1px!important;
            }
          }
        }
      }
    }
  }
}
</style>
